import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/img/cta.png";
const TokenCTA = () => {
	return (
		<section className="section-gap">
			<div className="container">
				<div className="cta-wrapper">
					<div className="cta-content">
						<h2 className="title">
							Earn up to 25% APY Staking our token
						</h2>
						<p>
							Ant is the utility token for providing innovative platform
							for fundraising on hepton layer 2 blockchain.
						</p>
						<div className="btn-grp">
							<Link to="https://documentation.hinox.io/" className="cmn-btn">
								Docs 
							</Link>
							<Link to="/staking" className="cmn-btn secondary-btn">
								Stake Now
							</Link>
						</div>
					</div>
					<div className="cta-thumb">
						<img src={img} alt="" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default TokenCTA;
