import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";
import img1 from "../assets/img/wallet/hepton.png";
import img2 from "../assets/img/wallet/metamask.png";
import img3 from "../assets/img/wallet/trust.png";
import img4 from "../assets/img/wallet/walleto.png";
import { Bar, BrightnessIcon, Close, Wallet } from "../IconTheme";
import DropdownGroup from "./DropdownGroup";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

const selectData = [
  {
    img: img1,
    name: "Hepton",
  },
];

const Header = () => {
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const { address, isConnecting, isDisconnected } = useAccount();

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <header>
        <div className="container">
          <div className="header-wrapper">
            <Link to="/" className="logo">
              <img src={logo} alt="" />
            </Link>
            <div className="bar ms-auto" onClick={() => setMenuOpen(!menuOpen)}>
              <Bar />
            </div>
            <div className={`menu-area  ${menuOpen ? "active" : ""}`}>
              <div className="close" onClick={() => setMenuOpen(!menuOpen)}>
                <Close />
              </div>
              <ul className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/staking">Staking</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/nft">Nft</NavLink>
                </li> */}
                <li>
                  <NavLink to="https://faucet.hinox.io">Faucet</NavLink>
                </li>
                {/* <li>
									<NavLink to="/antex">Create Token</NavLink>
								</li> */}
                <li>
                  <NavLink to="https://documentation.hinox.io/">Docs</NavLink>
                </li>
              </ul>
              {/* <div className="__gap-2 d-flex flex-wrap align-items-center">
                <Link to="#" className="brightness-icon">
                  <BrightnessIcon />
                </Link>
                <DropdownGroup data={selectData} />
              </div> */}
              <div className="header-btns d-flex flex-wrap align-items-center">
                {address && (
                  <Link onClick={open} className="cmn-btn">
                    <Wallet /> <span>Connected</span>
                  </Link>
                )}
                {!address && (
                  <Link onClick={open} className="cmn-btn">
                    <Wallet /> <span>Connect Wallet</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
