import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Twitter, Meet, Slack, Whatsapp, Youtube } from "../IconTheme";
import { FaDiscord, FaTelegram, FaTwitter } from "react-icons/fa";

const social_icons = [
  {
    icon: <FaTwitter />,
    link: "https://twitter.com/hinox_io",
  },
  {
    icon: <FaTelegram />,
    link: "https://t.me/hinox_io",
  },
  {
    icon: <FaDiscord />,
    link: "https://t.co/Oa2CUwdske",
  },
];

const SocialIcons = ({ data, className }) => {
  const [social, setSocial] = useState(data || social_icons);
  return (
    <>
      <ul className={`social-icons ${className ? className : ""}`}>
        {social.map(({ icon, link }, i) => (
          <li key={i}>
            <a href={link}>{icon}</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SocialIcons;
