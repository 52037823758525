import React, { useState } from "react";
import { MinusIcon, PlusIcon } from "../IconTheme";

const IncDecInput = (props) => {
	const value = props.stakeValue;
	const setValue = props.setStakeValue;
	const myData = props.myData;

	const handleChange = (e, amount) => {
		if (Number.isNaN(value) || value === "") {
			setValue(0);
		} else {
			if (e === "inc") {
				setValue(parseInt(value) + amount);
			} else {
				value >= amount ? setValue(parseInt(value) - amount) : setValue(0);
			}
		}
	};
	return (
		<>
			<div className="d-flex">
				<div
					className="dec"
					type="button"
					onClick={() => {
						
						handleChange("dec", 100);
					}}
				>
					<MinusIcon />
				</div>
				<div className="inc-input d-flex align-items-center justify-content-center">
					<span className="mt-2">{Number(value).toFixed()}</span>
					<input
						type="number"
						value={value}
						onChange={(e) => {
							if (Number.isNaN(value)) {
								setValue(0);
							} else {
								setValue(e.target.value);
							}
						}}
					/>
				</div>
				<div
					className="inc"
					type="button"
					onClick={() => {
						if(value >= myData.antBalance){
							setValue(myData.antBalance);
						}else{
							handleChange("inc", 100)
						}
						
					}}
				>
					<PlusIcon />
				</div>
			</div>
		</>
	);
};



const UnstakeIncDecInput = (props) => {
	const value = props.stakeValue;
	const setValue = props.setStakeValue;
	const myData = props.myData;

	const handleChange = (e, amount) => {
		if (Number.isNaN(value) || value === "") {
			setValue(0);
		} else {
			if (e === "inc") {
				setValue(Number(value) + amount);
			} else {
				value >= amount ? setValue(Number(value) - amount) : setValue(0);
			}
		}
	};
	return (
		<>
			<div className="d-flex">
				<div
					className="dec"
					type="button"
					onClick={() => {
						
						handleChange("dec", 100);
					}}
				>
					<MinusIcon />
				</div>
				<div className="inc-input d-flex align-items-center justify-content-center">
					<span className="mt-2">{(Number(value).toFixed(3))}</span>
					<input
						type="number"
						value={value}
						onChange={(e) => {
							if (Number.isNaN(value)) {
								setValue(0);
							} else {
								setValue(e.target.value);
							}
						}}
					/>
				</div>
				<div
					className="inc"
					type="button"
					onClick={() => {
						if(value >= myData.stake_balance){
							setValue(myData.stake_balance);
						}else{
							handleChange("inc", 100)
						}
						
					}}
				>
					<PlusIcon />
				</div>
			</div>
		</>
	);
};
export {
	IncDecInput,
	UnstakeIncDecInput
} ;
