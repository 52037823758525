import React from "react";
import { Link } from "react-router-dom";
import { BrightnessIcon, GlobeIcon } from "../IconTheme";
import SocialIcons from "./SocialIcons";

const Footer = () => {
  return (
    <footer className="bg-section">
      <FooterTop />
      <FooterBottom />
    </footer>
  );
};
const data = [
  {
    link: "https://faucet.antexpad.com",
    name: "Faucet",
  },
  {
    link: "/staking",
    name: "Staking",
  },
];
const data2 = [
  {
    link: "https://documentation.hinox.io/",
    name: "Docs",
  },
  {
    link: "#",
    name: "Github",
  },
  {
    link: "https://documentation.hinox.io/whitepaper/roadmap",
    name: "Roadmap",
  },
];
export const FooterTop = () => {
  return (
    <>
      <div className="footer-top">
        <div className="container">
          <div className="footer-wrapper">
            <div className="footer-widget widget-about">
              <h6 className="title">Subscribe to updates</h6>
              <form>
                <div className="__subscribe-form">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your e-mail"
                  />
                  <button className="icon btn cmn-btn">Register</button>
                </div>
              </form>
              <h6 className="title mt-57">Follow us</h6>
              <SocialIcons />
            </div>
            <div className="footer-widget widget-link">
              <h6 className="title">Quick Links</h6>
              <LinkList data={data} />
            </div>
            <div className="footer-widget widget-link">
              <h6 className="title">Development</h6>
              <LinkList data={data2} />
            </div>
            <div className="footer-widget widget-region">
              <h6 className="title"></h6>
              <div className="text">
                Providing innovative platform for fundraising on Hepton Layer 2
                Blockchain.
              </div>
              {/* <select className="cmn-btn outline-btn currency-select">
								<option>Currency - USD</option>
								<option>Currency - BDT</option>
								<option>Currency - INR</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const LinkList = ({ data }) => {
  return (
    <>
      <ul>
        {data &&
          data.map(({ link, name }, i) => (
            <li key={i}>
              <Link to={link}>{name}</Link>
            </li>
          ))}
      </ul>
    </>
  );
};
export const FooterBottom = () => {
  return (
    <>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-wrapper">
            <ul className="links">
              <li>
                <Link to="https://github.com/hinox-ecosystem/docs/blob/main/TERMS%20OF%20SERVICE.pdf">
                  Terms of service
                </Link>
              </li>
              <li>
                <Link to="https://github.com/hinox-ecosystem/docs/blob/main/COOKIES%20POLICY.pdf">
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link to="https://github.com/hinox-ecosystem/docs/blob/main/disclaimer.pdf">
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link to="https://github.com/hinox-ecosystem/docs/blob/main/privacy_policy.pdf">
                  Privacy Policy
                </Link>
              </li>
            </ul>
            <div className="">&copy; 2023 All_rights_reserved</div>
            <div className="lang-area">
              <div className="__lang-control">
                <select className="form-control">
                  <option>English</option>
                  <option>Bangla</option>
                  <option>Hindi</option>
                  <option>Urdu</option>
                  <option>Spanish</option>
                </select>
                <div className="globe-icon">
                  <GlobeIcon />
                </div>
              </div>
              <div>
                <Link to="#" className="color-theme-icon">
                  <BrightnessIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
