import React, { useState } from "react";
import {
  IncDecInput,
  UnstakeIncDecInput,
} from "../../../components/IncDecInput";
import {
  InputRemainders,
  InputRemainders2,
} from "../../../components/InputRemainders";
import { ethers } from "ethers";
import axios from "axios";
import { stakingABI } from "../../../constants/abi/staking";
import { erc20abi } from "../../../constants/abi/token";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { useSigner } from "wagmi";

const StakingCard = (props) => {
  const getData = props.getData;
  const [stakeValue, setStakeValue] = React.useState(0);
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { data: signer } = useSigner();
  const [allowance, setAllowance] = React.useState(0);
  const stakingContract = "0x2B5eDdd4402132e2a2004C665a5b071fA3c2abb7";
  const antexToken = "0xFb5e97E0a6443Db91ecB2c9F7Fdf7dfD2Ada0067";
  const provider = new ethers.providers.JsonRpcProvider(
    "https://testnet.hepton.io"
  );

  const stakeContract = new ethers.Contract(
    stakingContract,
    stakingABI,
    provider
  );
  const antexContract = new ethers.Contract(antexToken, erc20abi, provider);
  const antexSigner = new ethers.Contract(antexToken, erc20abi, signer);

  let user_approval = props.userData.allowance;

  const stakebuttonTitle = () => {
    if (!address) {
      return {
        title: "Connect Wallet",
        func: open,
      };
    } else if (user_approval == 0) {
      return {
        title: "Enable ANT-P",
        func: approveHandler,
      };
    } else {
      return {
        title: "Stake",
        func: stakeHandler,
      };
    }
  };

  const unstakebuttonTitle = () => {
    if (!address) {
      return {
        title: "Connect Wallet",
        func: open,
      };
    } else {
      return {
        title: "Unstake",
        func: withdrawHandler,
      };
    }
  };

  const withdrawButtonTitle = () => {
    if (!address) {
      return {
        title: "Connect Wallet",
        func: open,
      };
    } else {
      return {
        title: "Withdraw Earnings",
        func: withdrawEarningsHandler,
      };
    }
  };

  //

  const withdrawEarningsHandler = async () => {
    const staking = new ethers.Contract(stakingContract, stakingABI, signer);
    const tx = await staking.withdrawEarnings({ gasLimit: 300000 });
    const receipt = await tx.wait();
    // alerts.loading("Withdrawing")
    if (receipt.confirmations > 0) {
      // alerts.success("Withdrawal Successful");
      // init();
    }
  };

  //
  const approveHandler = async () => {
    try {
      const tx = await antexSigner.approve(
        stakingContract,
        ethers.utils.parseUnits("100000000000000000000000000"),
        { gasLimit: 300000, gasPrice: 20000000000 }
      );
      const receipt = await tx.wait();
      if (receipt.blockNumber) {
        // getData();
        // init();
        // alerts.success("Successful")
      }
    } catch (e) {
      console.log(e.error.data.message, "error");
      // alerts.error(e.error.data.message)
    }
  };

  const stakeHandler = async () => {
    let s = stakeValue;
    try {
      const staking = new ethers.Contract(stakingContract, stakingABI, signer);
      const tx = await staking.stake(
        ethers.utils.parseUnits(stakeValue.toString()),
        "0x0000000000000000000000000000000000000000",
        { gasLimit: 300000 }
      );
      const receipt = await tx.wait();
      setStakeValue(0);
      if (receipt.blockNumber) {
        // init();
        // alerts.success("Staking Successful")
      }
    } catch (e) {
      console.log(e.error.data.message, "error");
      // alerts.error(e.error.data.message)
    }
  };

  const withdrawHandler = async () => {
    const staking = new ethers.Contract(stakingContract, stakingABI, signer);
    const tx = await staking.unstake(
      ethers.utils.parseUnits(stakeValue.toString()),
      { gasLimit: 300000 }
    );
    const receipt = await tx.wait();
    // alerts.loading("Withdrawing")
    if (receipt.confirmations > 0) {
      getData();
      // alerts.success("Withdrawal Successful");
      // init();
    }
  };

  const stakeButton = stakebuttonTitle();
  const unstakeButton = unstakebuttonTitle();
  const withdrawButton = withdrawButtonTitle();
  const [userdetails, setuserdetails] = useState("stake");
  const [stake, setStake] = useState("stake");
  return (
    <div className="stake-card">
      <div className="stake-card-menu">
        <div className="inner-div">
          <button
            type="button"
            className={stake === "stake" ? "active" : ""}
            onClick={() => setStake("stake")}
          >
            Stake
          </button>
          <button
            type="button"
            className={stake === "withdraw" ? "active" : ""}
            onClick={() => setStake("withdraw")}
          >
            Withdraw
          </button>
        </div>
      </div>
      {stake === "stake" ? (
        <StakeCard
          setStakeValue={setStakeValue}
          stakeValue={stakeValue}
          userData={props}
          stakeButton={stakeButton}
        />
      ) : (
        <WidthDrawCard
          withdrawButton={withdrawButton}
          setStakeValue={setStakeValue}
          stakeValue={stakeValue}
          userData={props}
          unstakeButton={unstakeButton}
        />
      )}
    </div>
  );
};
export const StakeCard = (props) => {
  let myData = props.userData.userData;
  const stakeButton = props.stakeButton;
  const [duration, setDuration] = useState("30day");

  return (
    <>
      <div className="fadeIn">
        <div>
          <div className="__stake-count-badge-2">
            <span>Balance</span>{" "}
            <span>{Number(myData.antBalance).toFixed(4)} - ANT</span>
          </div>
          <div className="__stake-count-badge-2">
            <span>Stake balance:</span>{" "}
            <span>{Number(myData.stake_balance).toFixed(4)} - ANT</span>
          </div>
          {/* <div className="__stake-count-badge-2">
						<span>Unlock Time</span> <span>Mar 21, 2022 15:38</span>
					</div> */}
        </div>
        <div className="stake-cart-area">
          <IncDecInput
            setStakeValue={props.setStakeValue}
            stakeValue={props.stakeValue}
            myData={myData}
          />
          <InputRemainders
            setStakeValue={props.setStakeValue}
            stakeValue={props.stakeValue}
            myData={myData}
          />
        </div>
        <div className="stake-duration">
          <h6 className="subtitle m-0">Duration:</h6>
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ gap: "16px" }}
          >
            <span
              type="button"
              className={duration === "7day" && "active"}
              onClick={() => setDuration("7day")}
            >
              7 Day
            </span>
            <span
              type="button"
              className={duration === "30day" && "active"}
              onClick={() => setDuration("30day")}
            >
              30 Day
            </span>
            <span
              type="button"
              className={duration === "60day" && "active"}
              onClick={() => setDuration("60day")}
            >
              60 Day
            </span>
          </div>
        </div>
        <div className="mb-4">
          <button
            onClick={stakeButton.func}
            type="button"
            className="cmn-btn w-100"
          >
            {stakeButton.title}
          </button>
        </div>
        <div className="font-sm px-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span>Fee</span>
            <span>0.02 ANT</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Transaction Fee:</span>
            <span>0.00000 HT</span>
          </div>
        </div>
      </div>
    </>
  );
};
export const WidthDrawCard = (props) => {
  const { address } = useAccount();
  let myData = props.userData.userData;
  const withdrawButton = props.withdrawButton;
  const stakeButton = props.stakeButton;
  const [duration, setDuration] = useState("30day");
  return (
    <>
      <div className="fadeIn">
        <div>
          <div className="__stake-count-badge-2">
            <span>Balance</span>{" "}
            <span>{Number(myData.antBalance).toFixed(4)} - ANT</span>
          </div>
          <div className="__stake-count-badge-2">
            <span>Stake balance:</span>{" "}
            <span>{Number(myData.stake_balance).toFixed(4)} - ANT</span>
          </div>
          <div className="__stake-count-badge-2">
            <span>My Earnings</span>{" "}
            <span>
              {/* {Number(ethers.utils.formatEther(myData?.earnings)).toFixed(2)} */}
              {myData?.earnings ? Number(myData?.earnings).toFixed(2) : 0} - ANT
            </span>
          </div>
        </div>
        <div className="stake-cart-area">
          <UnstakeIncDecInput
            setStakeValue={props.setStakeValue}
            stakeValue={props.stakeValue}
            myData={myData}
          />
          <InputRemainders2
            setStakeValue={props.setStakeValue}
            stakeValue={props.stakeValue}
            myData={myData}
          />
        </div>
        <div className="stake-duration">
          <h6 className="subtitle m-0">Duration:</h6>
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ gap: "16px" }}
          >
            <span
              type="button"
              className={duration === "7day" && "active"}
              onClick={() => setDuration("7day")}
            >
              7 Day
            </span>
            <span
              type="button"
              className={duration === "30day" && "active"}
              onClick={() => setDuration("30day")}
            >
              30 Day
            </span>
            <span
              type="button"
              className={duration === "60day" && "active"}
              onClick={() => setDuration("60day")}
            >
              60 Day
            </span>
          </div>
        </div>
        <div className="mb-4">
          <button
            onClick={props.unstakeButton.func}
            type="button"
            className="cmn-btn w-100"
          >
            {props.unstakeButton.title}
          </button>
        </div>
        {address && (
          <div className="mb-4">
            <button
              onClick={withdrawButton.func}
              type="button"
              className="cmn-btn w-100"
            >
              {withdrawButton.title}
            </button>
          </div>
        )}
        <div className="font-sm px-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span>Fee</span>
            <span>0.02 ANT</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Transaction Fee:</span>
            <span>0.00000 HT</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default StakingCard;
