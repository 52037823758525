import React, { useEffect } from "react";
import ChartCard from "./components/ChartCard";
import StakingCard from "./components/StakingCard";
import StakingLeaderboard from "./components/StakingLeaderboard";
import { ethers } from "ethers";
import axios from "axios";
import { useAccount } from "wagmi";

const Staking = () => {
  const [userData, setUserData] = React.useState({});
  const { address: userAddress } = useAccount();
  const getData = async (userAddress) => {
    const res = await axios.post(
      `https://sea-turtle-app-p32ze.ondigitalocean.app/getStakeDetails/${userAddress}`
    );
    setUserData(res.data);
  };

  React.useEffect(() => {
    if (!userAddress) {
      getData("0x000000000000000000000000000000000000dEaD");
    }
    if (userAddress) {
      getData(userAddress);
    }
  }, [userData, getData]);

  return (
    <section className="section-gap">
      <div className="container">
        <div
          className="row g-2 g-sm-3 g-lg-4 justify-content-center"
          style={{ marginBottom: "24px" }}
        >
          <div className="col-lg-4 col-md-6">
            <div className="__stake-count-badge">
              <span>APR:</span>
              <span>25%</span>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6">
						<div className="__stake-count-badge">
							<span>Stakers:</span>
							<span>462</span>
						</div>
					</div> */}
          <div className="col-lg-4 col-md-6">
            <div className="__stake-count-badge">
              <span>Total Staked </span>
              <span>{Number(userData.totalStaked).toFixed(2)} - ANT-P</span>
            </div>
          </div>
        </div>
        <div className="row g-4 flex-column-reverse  flex-lg-row">
          <div className="col-lg-6">
            <div className="row g-4">
              <div className="col-12">
                <ChartCard />
              </div>
              <div className="col-12">
                <StakingLeaderboard />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <StakingCard userData={userData} getData={getData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Staking;
