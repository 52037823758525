import { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import "./Chart.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import TokenCTA from "./components/TokenCTA";
import Home from "./pages/home/Home";
import LaunchPad from "./pages/launchpad/LaunchPad";
import Staking from "./pages/staking/Staking";
import Hepton from "./pages/launchpad/Hepton";
import { useProvider } from "wagmi";

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { arbitrum, mainnet, polygon, bsc } from "wagmi/chains";
import Nft from "./pages/Nft/Nft";

const customChain = {
  id: 404,
  name: "tHepton",
  network: "tHepton",
  nativeCurrency: {
    decimals: 18,
    name: "Hepton",
    symbol: "HTE",
  },
  rpcUrls: {
    default: { http: ["https://testnet.hepton.io"] },
  },
  blockExplorers: {
    etherscan: { name: "HeptonScan", url: "https://testnet.heptonscan.com" },
    default: { name: "HeptonScan", url: "https://testnet.heptonscan.com" },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 11907934,
    },
  },
};
const chains = [customChain];

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "<YOUR_PROJECT_ID>" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: "d3873ed18b4ac26cf6f5dffdb2fe9cbd",
    version: "1", // or "2"
    appName: "Hinox Ecosystem",
    chains,
  }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  const provider = useProvider();
  provider.on("accountsChanged", (accounts) => {
    window.location.reload();
    alert(accounts);
  });
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <WagmiConfig client={wagmiClient}>
            <Web3Modal
              projectId="d3873ed18b4ac26cf6f5dffdb2fe9cbd"
              ethereumClient={ethereumClient}
            />
            <Header />
            <Routes>

              <Route path="/" element={<Staking />} />
            </Routes>
            <TokenCTA />
            <Footer />
          </WagmiConfig>
        </Wrapper>
      </BrowserRouter>
    </>
  );
}

export default App;
