import React, { useRef, useState } from "react";
import { CopyIcon, PriceChartIcon } from "../../../IconTheme";
import axios from "axios";
import truncateEthAddress from "truncate-eth-address";
import {ethers} from "ethers";

const StakingLeaderboard = () => {
	const [leaderboard , setLeaderboard] = useState([]);


	const query = `
{
	users (first: 100, orderBy:stakeAmountMonth,orderDirection:desc){
	  id
	  stakeAmountMonth
	}
  } `
	const fetchLeaderboard = async () => {
		const res  = await axios.post("https://graph.hinox.io/subgraphs/name/solidhash/antex", { query });
		setLeaderboard(res.data["data"]["users"]);
	}



	React.useEffect(() => {
		fetchLeaderboard();
	}, []);

	return (
		<div className="chart-card pb-0">
			<div className="price-chart-header d-flex flex-wrap align-items-center">
				<PriceChartIcon />
				<h5 className="m-0 mt-1 ps-2">Stake Leaderboard</h5>
			</div>
			<div className="staking-leaderboard">
				<ul className="staking-leaderboard-list">
					{leaderboard.map((item, i) => (
						<ListItem index={i} key={i} item={item} />
					))}
				</ul>
			</div>
		</div>
	);
};

export const 
ListItem = ({ index, item }) => {
	const [value, setValue] = useState(item.id);
	const [copy, setCopy] = useState(false);
	
	const ref = useRef();
	const handleClick = () => {
		navigator.clipboard.writeText(value);
		setTimeout(() => {
			setCopy(false);
		}, 1000);
	};
	return (
		<li>
			<div className="left">
				<span className="serial">
					<span>{index + 1}</span>
				</span>
				<span
					style={{ transform: "translateY(2px)" }}
					className="d-inline-flex"
				>
					<span className="me-1">Stake ANT-P:</span>
					<strong>{Number(ethers.utils.formatEther(item.stakeAmountMonth)).toFixed()}</strong>
				</span>
			</div>
			<div className="right">
				<input type="text" ref={ref} value={truncateEthAddress(item.id)} />
				<span
					type="button"
					onClick={() => {
						handleClick();
						setCopy(!copy);
					}}
				>
					{!copy ? <CopyIcon /> : "Copied"}
				</span>
			</div>
		</li>
	);
};

export default StakingLeaderboard;
