import React, { useState } from "react";

export const InputRemainders = (props) => {
	const user = props.myData;
	const setValue = props.setStakeValue;
	const [percentage, setPercentage] = useState("0");

	return (
		<>
			<div className="remainders">
				<button
					className={percentage === "25%" && "active"}
					onClick={() => {
						console.log(user.antBalance);
						setValue(user.antBalance * 0.25);
						setPercentage("25%");
					}}
				>
					<span>25%</span>
				</button>
				<button
					className={percentage === "50%" && "active"}
					onClick={() =>{ 
						setValue(user.antBalance * 0.5);
						setPercentage("50%")}}
				>
					<span>50%</span>
				</button>
				<button
					className={percentage === "75%" && "active"}
					onClick={() => {
						setValue(user.antBalance * 0.75);
						setPercentage("75%")}}
				>
					<span>75%</span>
				</button>
				<button
					className={percentage === "100%" && "active"}
					onClick={() => {
						setValue(user.antBalance);
						setPercentage("100%")}}
				>
					<span>100%</span>
				</button>
			</div>
		</>
	);
};

export const InputRemainders2 = (props) =>{
	const user = props.myData;
	const setValue = props.setStakeValue;
	const [percentage, setPercentage] = useState("0");

	return (
		<>
			<div className="remainders">
				<button
					className={percentage === "25%" && "active"}
					onClick={() => {
						console.log(user.stake_balance);
						setValue(user.stake_balance * 0.25);
						setPercentage("25%");
					}}
				>
					<span>25%</span>
				</button>
				<button
					className={percentage === "50%" && "active"}
					onClick={() =>{ 
						setValue(user.stake_balance * 0.5);
						setPercentage("50%")}}
				>
					<span>50%</span>
				</button>
				<button
					className={percentage === "75%" && "active"}
					onClick={() => {
						setValue(user.stake_balance * 0.75);
						setPercentage("75%")}}
				>
					<span>75%</span>
				</button>
				<button
					className={percentage === "100%" && "active"}
					onClick={() => {
						setValue(user.stake_balance);
						setPercentage("100%")}}
				>
					<span>100%</span>
				</button>
			</div>
		</>
	);
};

export const InputRemainders3 = (props) =>{
	const [percentage, setPercentage] = useState("0");
	const [value, setValue] = useState(0);

	return (
		<>
			<div className="remainders">
				<button
					className={percentage === "25%" && "active"}
					onClick={() => {
						console.log(1);
						setValue(1 * 0.25);
						setPercentage("25%");
					}}
				>
					<span>25%</span>
				</button>
				<button
					className={percentage === "50%" && "active"}
					onClick={() =>{ 
						setValue(1 * 0.5);
						setPercentage("50%")}}
				>
					<span>50%</span>
				</button>
				<button
					className={percentage === "75%" && "active"}
					onClick={() => {
						setValue(1 * 0.75);
						setPercentage("75%")}}
				>
					<span>75%</span>
				</button>
				<button
					className={percentage === "100%" && "active"}
					onClick={() => {
						setValue(1);
						setPercentage("100%")}}
				>
					<span>100%</span>
				</button>
			</div>
		</>
	);
};